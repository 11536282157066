<template>
  <div class="scan-login">

  </div>
</template>

<script>
import {defineComponent} from "vue";
import service from "@/utils/axios";
import {loginBill} from "@/views/common/Login/Login";
import {onPageLoadingEnd} from "@/utils/DLoading";

export default defineComponent({
  name: "ScanLogin",
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      let code = this.$route.query.code;
      const response = await service.post('/dwart/user/user/scan/code/login/h5', {code,})
      const {afterGetLoginInfo} = loginBill()
      if (response) {
        await afterGetLoginInfo(response)
      } else {
        setTimeout(() => {
          onPageLoadingEnd()
        }, 1000)
      }
    },
  },
})
</script>

<style lang="less" scoped>
.scan-login {

}
</style>
