import {reactive, toRefs} from "vue";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";
import {message} from "ant-design-vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";
import {$jsEncrypt} from "@/utils/DJsEncrypt";
import router from "@/router";

export enum ELoginType {
  Scan = '1',  // 扫码
  Form = '2',  // 表单输入
  Code = '3',  // 授权码登录
}

export interface IUserLogin {
  userName: string | null;
  password: string | null;
}

export interface ICodeLogin {
  keyword: string | null;
}


export interface ILoginState {
  loginType: ELoginType;
  form: IUserLogin;
  form2: ICodeLogin;
  isLoading: boolean;   // 目前操作状态
    timeId:any,//定时器
    qrCodeStatus:Boolean,//二维码状态 true失效反之有效
    queryLoginCodeList:object,
    code:any,
    timeId1:any,
    dataContainer:any,
}

export function loginBill(){
  const route = useRoute();
  const APP_ID  = require('@/dw.config.json').DD_APPID;

  const REDIRECT_URL = encodeURIComponent(`${location.origin}/#/scanLogin`);
  let stateRef = reactive(<ILoginState>{
    loginType: ELoginType.Scan,
    form: {
      userName: null,
      password: null,
    },
    form2: {
      keyword: null,
    },
    isLoading: false,
      timeId:1,
      timeId1:1,
      qrCodeStatus:false,
      queryLoginCodeList:{},
      code:null,
      dataContainer:[],
  });

  let refData = toRefs(stateRef)

  const init = async () => {
    await router.push({
      name: 'TimetableIndex'
    })
  }

  const queryLoginCode = async ()=>{
     let res =  await service.post('/dwart/an_teaching/lessonBoxCharm/queryLoginCode', {
          "mac": localStorage.getItem('macAd'),
          type: 2,
      })
      stateRef.queryLoginCodeList = res || {}
      stateRef.code = JSON.parse(JSON.stringify(res)).code
      stateRef.qrCodeStatus = false
      await ceaningTools()
  }

  const getApiQueryAuthorizationStatus = async () => {
    let res : any = await service.post('/dwart/an_teaching/lessonBoxCharm/queryAuthorizationStatus',{
        code: stateRef.code
      })
      stateRef.dataContainer = res
      if ( res.userLoginModel ){
          clearTimeout(stateRef.timeId)
          clearTimeout(stateRef.timeId1)
          // @ts-ignore
          await afterGetLoginInfoCopy(res)
      }
  }

    const afterGetLoginInfoCopy = async (data: any) => {
        // 获取登录信息之后
        if (!data) {
            await loginTypeChange(false, data.message, 'error')
        } else {
            await loginTypeChange(false, data.message)
            const { mobile, name, sex, token, message, avatar } = data.userLoginModel;
            const user = {
                mobile,
                name,
                sex,
                message,
                avatar,
            };

            localStorage.setItem("dw__t", token);
            localStorage.setItem("user", JSON.stringify(user));

            try{
                await getReportAdd();
            }catch (e){
                console.error(e);
            }finally {
                await router.replace({
                    name: 'TimetableIndex',
                })
            }
        }
    }


  const clearTimeoutFun = async () => {
      clearTimeout(stateRef.timeId)
      clearTimeout(stateRef.timeId1)
  }

  const ceaningTools = async () => {
      clearTimeout(stateRef.timeId)
      clearTimeout(stateRef.timeId1)
      stateRef.timeId = setTimeout(()=>{
          clearTimeout(stateRef.timeId)
          clearTimeout(stateRef.timeId1)
          stateRef.qrCodeStatus = true
      },180 * 1000)
      stateRef.timeId1 = setInterval(getApiQueryAuthorizationStatus,1000)
  }

  const getReportAdd = async ()=> {
    message.loading('正在跳转...', 0)
    await service.post('/dwart/an_teaching/loginRecord/v1/loginRecord', {
      "mac": localStorage.getItem('macAd'),
      "version": "2.3",
      "type": 1
    })
    message.destroy();
  }

  const getLoginByUser = async () => {
    const r = await loginTypeChange(true)
    if (r) {
      const response = await service.post('/dwart/user/user/userLogin', {
        mobile: stateRef.form.userName,
        cipherText: $jsEncrypt(stateRef.form.password)
      })
      await afterGetLoginInfo(response)
    }
  }

  const getLoginByCode = async () => {
    const r = await loginTypeChange(true)
    if (r) {
      const response = await service.post('/dwart/user/user/az/temporaryLogin', {
        code: stateRef.form2.keyword
      })
      await afterGetLoginInfo(response)
    }
  }

  const getDDQrCode = () => {
    let redirectUrl = encodeURIComponent(`https://oapi.dingtalk.com/connect/qrconnect?appid=${APP_ID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${REDIRECT_URL}`)
    // @ts-ignore
    DDLogin({
      id: "login__qrcode",
      goto: redirectUrl,
      style: "border:none;background-color:#FFFFFF;",
      width: "300",
      height: "300"
    });
    bindDDScan();
  }

  const onLoginMethodChange = (type: ELoginType) => {
    console.log(type)
    stateRef.loginType = type;
  }

  const onMessage = async (event: any) => {
    let origin = event.origin;
    if (origin == "https://login.dingtalk.com") { //判断是否来自ddLogin扫码事件。
      let loginTmpCode = event.data;

      //拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
      await getLoginByScan(loginTmpCode);
    }
  }

  const bindDDScan = () => {
    if (typeof window.addEventListener !== "undefined") {
      window.addEventListener('message', onMessage, false);
    }
  }

  const getLoginByScan = async (code: string) => {
    // 扫码之后
    // const r = await loginTypeChange(true)
    window.location.href =
      `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${APP_ID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${REDIRECT_URL}&loginTmpCode=` +
      code;
    console.log(code);
    // if (r) {
    //   const response = await service.post('/dwart/user/user/scan/code/login/h5', {code: code})
    //   if (response){
    //     await afterGetLoginInfo(response)
    //   }else{
    //     setTimeout(()=>{
    //       onPageLoadingEnd()
    //       stateRef.isLoading = false
    //     }, 1000)
    //   }
    //
    // }
  }

  const loginTypeChange = async (isStart: boolean, other?: string, type?: string): Promise<boolean> => {
    if (isStart) {
      if (!stateRef.isLoading) {
        onPageLoadingStart('正在登陆...')
        stateRef.isLoading = true
        return true
      } else {
        message.error('正在登陆中,请不要重复点击')
        return false
      }
    } else {
      stateRef.isLoading = false
      onPageLoadingEnd(other ? other : '登录成功', type)
      return true
    }
  }


  const afterGetLoginInfo = async (data: any) => {
    // 获取登录信息之后
    if (!data){
      stateRef.isLoading = false
      setTimeout(()=> {
        onPageLoadingEnd()
      }, 1000)
    }
    if (!data.result) {
      await loginTypeChange(false, data.message, 'error')
    } else {
      await loginTypeChange(false, data.message)

      const { mobile, name, sex, token, message, avatar } = data;
      const user = {
        mobile,
        name,
        sex,
        message,
        avatar,
      };

      localStorage.setItem("dw__t", token);
      localStorage.setItem("user", JSON.stringify(user));

      try{
        await getReportAdd();
      }catch (e){
        console.error(e);
      }finally {
        await router.replace({
          name: 'TimetableIndex',
        })
      }
    }
  }
  const reloadTip = () => {
    if (route.query.type === 'reload') {
      message.warning('登录凭证失效，请重新登录')
    }
  }

  const onFinish = async (type: number) => {
    // 提交验证通过后
    console.log('submit')
    if (type === 1){
      await getLoginByUser();
    }else{
      await getLoginByCode();
    }
  }

  const onFinishFailed = async () => {
    // 提交表单且数据验证失败后回调事件
    message.error('输入错误')

  }

  const onCloseProject = () => {
    // 退出系统
    // @ts-ignore
    window.electronAPI.closeProject()
  }

  return {
    refData,
    ELoginType,
    init,
    onLoginMethodChange,
    getDDQrCode,
    reloadTip,
    onFinish,
    onFinishFailed,
    onCloseProject,
    afterGetLoginInfo,
      queryLoginCode,
      clearTimeoutFun,
  }
}
