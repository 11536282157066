// @ts-ignore
import JsEncrypt from 'encryptlong'
// @ts-ignore
import CryptoJS from 'crypto-js'

export const $jsEncrypt = (password: string|null) => {
  const encrypt = new JsEncrypt()
  encrypt.setPublicKey(require('@/dw.config.json').JS_ENCRYPT)
  return encrypt.encrypt(password)
}


export const encodeEncrypt = (str: string) => {
  const encrypt = new JsEncrypt()
  encrypt.setPublicKey(require('@/dw.config.json').ENCRYPT_USER_PUBLIC)
  encrypt.setPublicKey(require('@/dw.config.json').ENCRYPT_USER_PRIVATE)
  return encrypt.encryptLong(str)
}

export const decodeEncrypt = (str: string) => {
  const encrypt = new JsEncrypt()
  encrypt.setPublicKey(require('@/dw.config.json').ENCRYPT_USER_PUBLIC)
  encrypt.setPublicKey(require('@/dw.config.json').ENCRYPT_USER_PRIVATE)
  return encrypt.decryptLong(str)
}

export const encodeCrypto = (str: string) => {
  return CryptoJS.AES.encrypt(str, CryptoJS.enc.Utf8.parse(require("@/dw.config.json").CRYPTO_KEY), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString()
}

export const decodeCrypto = (str:string) => {
  return CryptoJS.AES.decrypt(str, CryptoJS.enc.Utf8.parse(require("@/dw.config.json").CRYPTO_KEY), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }).toString(CryptoJS.enc.Utf8);
}
